import { Outlet, useLoaderData } from "@remix-run/react";
import mapBg from "~/assets/map-bg.svg";
import appPreview from "~/assets/app-preview.jpg";
import { Logo } from "~/components/logo";
import type { LoaderFunctionArgs, MetaFunction } from "@remix-run/node";
import { json } from "@remix-run/node";
import { getSession } from "~/lib/session.server";

export async function loader({ request }: LoaderFunctionArgs) {
  const session = await getSession(request);
  const duplication = session.has("duplicateMap")
    ? session.get("duplicateMap")
    : null;
  if (duplication) {
    const map = JSON.parse(duplication) as { id: string; title: string };
    return json({
      map,
      theme: false,
    });
  }

  const shared = await getSession(request);
  if (shared.has("fromDesigner")) {
    return json({
      theme: true,
      map: null,
    });
  }

  return json({
    map: null,
    theme: false,
  });
}

export const meta: MetaFunction = () => {
  return [
    {
      name: "description",
      content:
        "Carta is a no code platform that provides an intuitive user interface to build, customize and share custom maps.",
    },
    {
      property: "og:description",
      content:
        "Carta is a no code platform that provides an intuitive user interface to build, customize and share custom maps.",
    },
    {
      property: "og:title",
      content: "Carta Maps - Easily create, customize and share maps",
    },
    {
      property: "og:image",
      content: "/social/open-graph.jpg",
    },
  ];
};

export default function AuthLayout() {
  const { map, theme = false } = useLoaderData<typeof loader>();

  return (
    <div className="flex min-h-screen flex-col">
      <main
        id="main"
        className="grid grid-cols-1 lg:grid-cols-2 flex-1 relative"
      >
        <img
          className="absolute inset-0 h-full w-full object-cover opacity-50"
          src={mapBg}
          alt=""
        />
        <div className="flex items-center justify-center overflow-hidden relative z-10 px-8">
          <div className="flex flex-col items-center lg:items-start gap-y-2 w-full max-w-lg">
            <Logo className="h-16" />
            <p className="text-4xl font-medium tracking-tight">
              Build Better Looking Maps
            </p>
            <img
              className="hidden lg:block mt-6 -ml-6 xl:-ml-8 origin-top-left transform scale-150 shadow-2xl"
              src={appPreview}
              alt=""
            />
          </div>
        </div>
        <div className="flex flex-1 flex-col items-center lg:justify-center lg:bg-muted p-6 relative z-10">
          {map && (
            <div className="absolute top-4 inset-x-0 flex justify-center px-6">
              <div className="w-full rounded-lg bg-foreground text-background px-2 py-3 text-center shadow-2xl">
                Duplicating {map.title}
              </div>
            </div>
          )}
          {theme && (
            <div className="absolute top-4 inset-x-0 flex justify-center px-6">
              <div className="w-full rounded-lg bg-foreground text-background px-2 py-3 text-center shadow-2xl">
                Theme from designer.cartamaps.com is ready
              </div>
            </div>
          )}
          <Outlet />
        </div>
      </main>
    </div>
  );
}
